import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchRepo, fetchRepoList } from '../../redux/actions/repoActions'
import { Row, Col, FormGroup, FormSelect } from 'shards-react'

const mapStateToProps = state => ({
  active_repo: state.repo.activeRepo,
  repos: state.repo.repos,
  acct: state.acct.ACCT
})

const mapDispatchToProps = dispatch => ({
  getRepo: (ID) => dispatch(fetchRepo(ID)),
  getRepoList: (ID) => dispatch(fetchRepoList(ID)),
})

export default connect(mapStateToProps,mapDispatchToProps)(({ repos, active_repo, getRepo, getRepoList, acct }) => {

  useEffect(() => {
    if(acct.ID) {
      if(!active_repo || active_repo.TJT !== 'FULL') {
        getRepo({ACCT: acct.ID, REPO: acct.REPO.ID})
      }
      if(repos === undefined && repos !== null) {
        getRepoList({ACCT: acct.ID})
      }
    }
  })

  return (
    <Row>
      <Col>
        <FormGroup className="">
          <FormSelect className="w-50" >
            {repos && repos.map((repo,idx) => {
              return (
                <option onSelect={(e) => {}} key={idx}>{repo.NM}</option>
              )
            })}
          </FormSelect>
        </FormGroup>
        </Col>
    </Row>
  )
})