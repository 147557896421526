import axios from '../../connection/axiosHandler'
import url from '../../connection/url'
import store from '../store'
import { fetchDocuments,setActiveDirectoryContents,fetchFolderContentsBatchSuccess } from './repoActions'
import { validateSessionError,authenticateFailure } from './authActions'

export const ADD_UPL_JOBS = "ADD_UPL_JOBS"
export const CLEAR_FILE_JOBS = "CLEAR_FILE_JOBS"

export const ADD_FILE_JOBS = "ADD_FILE_JOBS"
export const UPDATE_FILE_JOBS = "UPDATE_FILE_JOBS"

export const ADD_UPL_BEGIN = "ADD_UPL_BEGIN"
export const ADD_UPL_SUCCESS = "ADD_UPL_SUCCESS"
export const ADD_UPL_FAILURE = "ADD_UPL_FAILURE"

export const ADD_GLOB_BEGIN = "ADD_GLOB_BEGIN"
export const ADD_GLOB_SUCCESS = "ADD_GLOB_SUCCESS"
export const ADD_GLOB_FAILURE = "ADD_GLOB_FAILURE"

export const COMMIT_UPL_BEGIN = "COMMIT_UPL_BEGIN"
export const COMMIT_UPL_SUCCESS = "COMMIT_UPL_SUCCESS"
export const COMMIT_UPL_FAILURE = "COMMIT_UPL_FAILURE"

export const ADD_DOC_BEGIN = "ADD_DOC_BEGIN"
export const ADD_DOC_SUCCESS = "ADD_DOC_SUCCESS"
export const ADD_DOC_FAILURE = "ADD_DOC_FAILURE"

export const SET_ACTIVE_DOCTYPE_BEGIN = "SET_ACTIVE_DOCTYPE_BEGIN"
export const SET_ACTIVE_DOCTYPE_SUCCESS = "SET_ACTIVE_DOCTYPE_SUCCESS"
export const SET_ACTIVE_DOCTYPE_FAILURE = "SET_ACTIVE_DOCTYPE_FAILURE"

export const CLEAR_ACTIVE_UPL_DOCTYPE = "CLEAR_ACTIVE_UPL_DOCTYPE"

export const UPDATE_ACTIVE_UPLOAD_DOC = "UPDATE_ACTIVE_UPLOAD_DOC"
export const UPDATE_ACTIVE_UPLOAD_DOC_FAILURE = "UPDATE_ACTIVE_UPLOAD_DOC_FAILURE"

export const _addFileJobs = (fJobs) => ({
    type: ADD_FILE_JOBS,
    payload: fJobs
})

export const clearFileJobs = () => ({
    type: CLEAR_FILE_JOBS
})
 
export const updateFileJobs = (jobs) => ({
    type: UPDATE_FILE_JOBS,
    payload: jobs
})

export const addUploadJobs = (jobs) => ({
    type: ADD_UPL_JOBS,
    payload: jobs
})

export const addUploadBegin = (jobs) => ({
    type: ADD_UPL_BEGIN,
    payload: jobs
})

export const addUploadSuccess = (jobs) => ({
    type: ADD_UPL_SUCCESS,
    payload: jobs
})

export const addUploadFailure = (ERR) => ({
    type: ADD_UPL_FAILURE,
    payload: ERR
})

export const addGlobBegin = (jobs) => ({
    type: ADD_GLOB_BEGIN,
    payload: jobs
})

export const addGlobSuccess = (jobs) => ({
    type: ADD_GLOB_SUCCESS,
    payload: jobs
})

export const addGlobFailure = (ERR) => ({
    type: ADD_GLOB_FAILURE,
    payload: ERR
})

export const commitUploadBegin = () => ({
    type: COMMIT_UPL_BEGIN
})

export const commitUploadSuccess = (jobs) => ({
    type: COMMIT_UPL_SUCCESS,
    payload: jobs
})

export const commitUploadFailure = (ERR) => ({
    type: COMMIT_UPL_FAILURE,
    payload: ERR
})

export const addDocumentBegin = (jobs) => ({
    type: ADD_DOC_BEGIN,
    payload: jobs
})

export const addDocumentSuccess = (jobs) => ({
    type: ADD_DOC_SUCCESS,
    payload: jobs
})

export const addDocumentFailure = (err) => ({
    type: ADD_DOC_FAILURE,
    payload: err
})

export const setActiveDocTypeBegin = () => ({
    type: SET_ACTIVE_DOCTYPE_BEGIN
})

export const setActiveDocTypeSuccess = (DOCTYPE) => ({
    type: SET_ACTIVE_DOCTYPE_SUCCESS,
    payload: DOCTYPE
})

export const setActiveDocTypeFailure = (ERR) => ({
    type: SET_ACTIVE_DOCTYPE_FAILURE,
    payload: ERR
})

export const updateActiveUploadDoc = (UPL) => ({
    type: UPDATE_ACTIVE_UPLOAD_DOC,
    payload: UPL
})

export const updateActiveUploadDocFailure = (ERR) => ({
    type: UPDATE_ACTIVE_UPLOAD_DOC_FAILURE,
    payload: ERR
})

export const clearActiveUploadDocType = () => ({
    type: CLEAR_ACTIVE_UPL_DOCTYPE
})


/**
 * addFileJobs adds an array of Objects needing configuration 
 * before uploading.
 * 
 * @param {Array} fJobs 
 */
export const addFileJobs = (jobs) => {
    return async dispatch => {
        if(jobs !== null ? jobs.length > 0 : false) {
            let uplJobs = store.getState().upload.fileJobs
            if(jobs !== null ? jobs.length > 0 : false) {
                // uplJobs.push(jobs)
                let files = [
                    ...uplJobs,
                    jobs[0]
                ]
                console.log('pushing files: ', files)
                dispatch(_addFileJobs(files))
            }
        }
    }
}

/**
 * addJobs adds the newly created uploadJobs to the 
 * global store array.
 * 
 * @param {Array} jobs 
 */
export const addJobs = (jobs) => {
    return async dispatch => {
        if(jobs !== null ? jobs.length > 0 : false) {
            dispatch(addUploadJobs(jobs))
        }
        else  {
            console.log('failed to dispatch?')
        } 
    }
}

/**
 * addUpload creates a new Upload on the server and initiates the 
 * transfer of the file by giving descriptive information on the file
 * and data to be transfered. The server will respond with the upload's 
 * unique ID.
 * 
 * @param {ID:{
 *          FLNM:(filename),
 *          SZ:(file size in bytes),
 *          GBS:(glob count),
 *          GBSZ:(glob(s) size in bytes),
 *          ENCD: (encrypted? -- this will either always be left out or false from web.)}} ID 
 */
export const addUpload = (ID) => {
    return async dispatch => {
        try {
            let jobs = store.getState().upload.uploadJobs
            if(jobs !== null ? jobs.length > 0 : false) {
                for(let job of jobs) {
                    if(job.FLNM === ID.FLNM) {
                        job.STEP = 'add-upload-await'
                        break
                    }
                }
            }
            dispatch(addUploadBegin(jobs))
            const response = await axios({
                baseURL: `${url}/xmits/uls/add`,
                crossDomain: true,
                timeout: 200000,
                withCredentials: true,
                credentials: 'same-origin',
                responseType: 'json',
                method: 'post',
                data: ID
            })
            if(response.data.ERR !== null) {
                if(validateSessionError(response.data.ERR))
                    dispatch(authenticateFailure())
                dispatch(addUploadFailure(response.data.ERR))
            }else if(response.data.RSP !== null) {
               
                if(store.getState().upload.uploadJobs !== null ? store.getState().upload.uploadJobs.length > 0 : false) {
                    let jobs = store.getState().upload.uploadJobs
                    for(let job of jobs) {
                        if(job.FLNM === ID.FLNM) {
                            job.ID = response.data.RSP.UL.ID
                            job.STEP = 'add-glob'
                            job.GBSZ = Number(job.GBSZ)
                            job.SZ = Number(job.SZ)

                            break
                        }
                    }
                    dispatch(addUploadSuccess(jobs))
                }else {
                    dispatch(addUploadFailure("addUpload.error: Unable to locate existing Upload Jobs."))
                }
            }
        }catch(error) {
            dispatch(addUploadFailure("addUpload.error: ", error))
        }
    }
}

/**
 * addUploadGlob is responsible for letting the system know 
 * a current glob transfer is in progress, sending that glob 
 * to the server, and once acknkowledgement is received, letting
 * the system know the glob transfer is complete.
 * 
 * @param {ID:{IDX:(GLOB INDEX),
 *         UPL:(UPLOAD ID),
 *         GLB:{GBSZ:(GLOB SIZE),D:(RAW GLOBBED DATA)}}} ID 
 */
export const addUploadGlob = (ID) => {
    return async dispatch => {
        try {
            let jobs = store.getState().upload.uploadJobs
            if(jobs !== null ? jobs.length > 0 : false) {
                for(let job of jobs) {
                    if(job.ID === ID.ID) {
                        job.STEP = 'await-glob'
                        break
                    }
                }
                dispatch(addGlobBegin(jobs))

                const response = await axios({
                    baseURL: `${url}/xmits/uls/${ID.ID}/glob/${ID.IDX}/add`,
                    crossDomain: true,
                    timeout: 200000,
                    withCredentials: true,
                    credentials: 'same-origin',
                    responseType: 'json',
                    method: 'post',
                    data: ID.GLB
                })
                if(response.data.ERR !== null) {
                    if(validateSessionError(response.data.ERR))
                        dispatch(authenticateFailure())
                    dispatch(addGlobFailure(response.data.ERR))
                }else if(response.data.RSP !== null) {
                    for(let job of jobs) {
                        if(job.ID === ID.ID) {
                            if(ID.IDX === job.GBS) {
                                job.STEP = 'commit-upload'
                                job.PCT = 100
                                break
                            }else {
                                job.STEP = 'add-glob'
                                job.PCT = Math.round(job.IDX * (100/job.GBS))
                                job.IDX++
                                break
                            }
                            
                        }
                    }
                    dispatch(addGlobSuccess(jobs))
                }
            }else {
                dispatch(addGlobFailure())
            }
        }catch(error) {
            dispatch(addGlobFailure("addUploadGlob.error: ", error))
        }
    }
}

/**
 * commitUpload notifies the server that the upload has completed and all
 * parts of the file have been sent.
 * 
 * @param {ID:{UPL:(UPLOAD ID)}} ID 
 */
export const commitUpload = (ID) => {
    return async dispatch => {
        try {
            let jobs = store.getState().upload.uploadJobs
            if(jobs !== null ? jobs.length > 0 : false) {
                for(let job of jobs) {
                    if(job.ID === ID.ID) {
                        job.STEP = 'commit-upload-await'
                        break
                    }
                }
            }
            dispatch(commitUploadBegin(jobs))
            const response = await axios({
                baseURL: `${url}/xmits/uls/${ID.ID}/commit`,
                crossDomain: true,
                timeout: 200000,
                withCredentials: true,
                credentials: 'same-origin',
                responseType: 'json',
                method: 'get'
            })
            if(response.data.ERR !== null) {
                console.log('response.data.ERR: ', response.data.ERR)
                if(validateSessionError(response.data.ERR))
                    dispatch(authenticateFailure())
                dispatch(commitUploadFailure(response.data.ERR))
            }else if(response.data.RSP !== null) {
                dispatch(clearFileJobs())
                dispatch(fetchDocuments({
                    ACCT:store.getState().acct.ACCT.ID,
                    REPO:store.getState().repo.activeRepo.ID,
                    FLDR:store.getState().repo.activeDir.ID
                }))
                // let acct 
                // if((acct = JSON.parse(sessionStorage.getItem('USR')).USR.ACCT) !== null) {
                //     console.log('refreshing latest uploaded doc.')
                //     dispatch(refreshDocument({ACCT: acct.ID,UPL:ID.ID}))
                // }else
                //     console.log('commitupload, acct null')


                let jobs = store.getState().upload.uploadJobs
                if(jobs !== null ? jobs.length > 0 : false) {
                    for(let job of jobs) {
                        if(job.ID === ID.ID) {
                            job.CMPD = true
                            break
                        }
                    }
                    dispatch(commitUploadSuccess(jobs))
                }
            }
        }catch(error) {
            console.log("commitUploadError: ", error)
            dispatch(commitUploadFailure("commitUpload.error: ", error))
        }
    }
}

export const refreshDocument = (ID) => {
    console.log('refreshDocument pre dispatch')
    return async dispatch => {
        console.log('inside dispatch')
        try {
            if(ID.UPL !== undefined && ID.ACCT !== null) {
                let response = await axios({
                    baseURL: `${url}/accts/${ID.ACCT}/docs`,
                    crossDomain: true,
                    timeout: 200000,
                    withCredentials: true,
                    credentials: 'same-origin',
                    responseType: 'json',
                    method: 'post',
                    data: {ID:ID.UPL}
                })
                if(response.data.ERR !== null) {
                    if(validateSessionError(response.data.ERR))
                        dispatch(authenticateFailure())
                }else if(response.data.RSP !== null) {
                    let contents = store.getState().repo.activeDirContents
                    
                    for(let item of contents) {
                        if(item.ID.toString() === response.data.RSP.DOC.ID.toString()) {
                            console.log("adding updated doc to array: ", response.data.RSP.DOC)
                            contents.splice(contents.indexOf(item),1,{...response.data.RSP.DOC,TP:'DOC'})
                            let newContents = contents
                            dispatch(fetchFolderContentsBatchSuccess(newContents))
                            break
                        }
                    }
                }

            }
        }catch(error) {
            console.log('error updating newly uploaded Document.')
        }
    }
}

export const addDocument = (ID) => {
    console.log('addDocument, ID: ', ID)
    return async dispatch => {
        try {
            let jobs = store.getState().upload.uploadJobs
            if(jobs !== null ? jobs.length > 0 : false) {
                for(let job of jobs) {
                    if(job.FLNM === ID.FLNM) {
                        job.STEP = 'add-document-await'
                        break
                    }
                }
            }
            dispatch(addDocumentBegin(jobs))
            if(ID.DOC !== undefined && ID.DOC !== null) {
                let response = await axios({
                    baseURL: `${url}/accts/${ID.DOC.ACCT.ID}/repos/${ID.DOC.REPO.ID}/fldrs/${ID.DOC.FLDR.ID}/docs/add`,
                    crossDomain: true,
                    timeout: 200000,
                    withCredentials: true,
                    credentials: 'same-origin',
                    responseType: 'json',
                    method: 'post',
                    data: ID.DOC
                })
                if(response.data.ERR !== null) {
                    if(validateSessionError(response.data.ERR))
                        dispatch(authenticateFailure())
                    dispatch(addDocumentFailure(response.data.ERR))
                }else if(response.data.RSP !== null) {
                    let jobs = store.getState().upload.uploadJobs
                    if(jobs !== null ? jobs.length > 0 : false) {
                        for(let job of jobs) {
                            if(job.FLNM === ID.FLNM) {
                                job.DOC.ID = response.data.RSP.DOC.ID
                                job.STEP = 'add-upload'
                                break
                            }
                        }
                        // dispatch(addDocumentSuccess(jobs))
                        let contents = store.getState().repo.activeDirContents
                        if(contents !== undefined ? contents !== null : false) {
                            for(let item of contents) {
                                if(item.TP === 'DOC') {
                                    let newcontents = contents.splice(contents.indexOf(item),0,{...response.data.RSP.DOC,TP:'DOC'})
                                    dispatch(addDocumentSuccess(jobs))
                                    dispatch(setActiveDirectoryContents(newcontents))
                                    break
                                }else if(contents.indexOf(item) === contents.length-1) {
                                    let newcontents = contents.push({...response.data.RSP.DOC,TP:'DOC'})
                                    dispatch(addDocumentSuccess(jobs))
                                    dispatch(setActiveDirectoryContents(newcontents))
                                    break
                                }
                            }
                        }else {
                            contents = []
                            contents.push({...response.data.RSP.DOC,TP:'DOC'})
                            dispatch(addDocumentSuccess(jobs))
                            dispatch(setActiveDirectoryContents(contents))
                        }
                    }else {
                        dispatch(addDocumentFailure('addDocument.error: unable to find uploadJob list.'))
                    }
                }
            }else {
                dispatch(addDocumentFailure('addDocument.error: unable to verify contents of new Document.'))
            }
        }catch(error) {
            dispatch(addDocumentFailure(error))
        }
    }
}

/**
 * setActiveUploadDocType is used to pull the correct doctype
 * down from the server in order to allow the user to fill out the 
 * required doctype fields for a pending upload.
 * 
 * @param {*} ID The DocType's Id.
 */
export const setActiveUploadDocType = (ID) => {
    return async dispatch => {
        try {
            if(ID !== null && ID > 0) {
                let acctId = JSON.parse(sessionStorage.getItem('USR')).USR.ACCT.ID
                if(acctId !== null && acctId > 0) {
                    dispatch(setActiveDocTypeBegin())
                    let response = await axios({
                        baseURL: `${url}/accts/${acctId}/doctps/${ID}`,
                        crossDomain: true,
                        timeout: 200000,
                        withCredentials: true,
                        credentials: 'same-origin',
                        responseType: 'json',
                        method: 'get'
                    })
                    if(response.data.ERR !== null) {
                        if(validateSessionError(response.data.ERR))
                            dispatch(authenticateFailure())
                        throw(response.data.ERR)
                    }else if(response.data.RSP !== null) {
                        dispatch(setActiveDocTypeSuccess(response.data.RSP.DOCTP))
                    }
                }else {
                    throw('Unable to verify Acct Id and/or DocType Id.')
                }
            }
        }catch(error) {
            dispatch(setActiveDocTypeFailure(error))
        }
    }
}

/**
 * updateActiveDoc updates the document object with the new or edited fields
 * provided.
 * 
 * @param {*} doc The Upload Doc.
 */
export const updateActiveDoc = (doc) => {
    console.log('activeDocUpdate: ', doc)
    return async dispatch => {
        try {
            if(doc !== null && doc !== undefined) {
                let upls = store.getState().upload.fileJobs
                if(upls !== null && upls !== undefined) {
                    let upload = upls[0]
                    upload = {
                        ...upload,
                        DOC: doc
                    }
                    dispatch(updateActiveUploadDoc(upload))
                    // onClick={} {
                    throw("Unable to verify Upload Job. Cannot update Doc.")
                }
            }else {
                throw("Unable to verify Document. Cannot update.")
            }
        }catch(error) {
            console.log('updateActiveDoc: Error: ', error)
            dispatch(updateActiveUploadDocFailure(error))
        }
    }
}