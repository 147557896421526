import { authState } from '../initialState'

import {
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,

  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  FETCH_ENDPOINT_BEGIN,
  FETCH_ENDPOINT_SUCCESS,
  FETCH_ENDPOINT_FAILURE,

  EDIT_LOGIN_FORM,

  DEAUTH,

  RESET_APP,

  PW_RESET_REQUEST_BEGIN,
  PW_RESET_REQUEST_SUCCESS,
  PW_RESET_REQUEST_FAILURE,

  PW_UPDATE_REQUEST_BEGIN,
  PW_UPDATE_REQUEST_SUCCESS,
  PW_UPDATE_REQUEST_FAILURE,

  CLEAR_ERR,

  SET_AUTHZ_TIMER

} from '../actions/authActions'


/**
 * REDUCER CREATED TO HANDLE AUTHENTICATION 
 * @param {*} state the state to update
 * @param {*} action the action to perform
 */
export default (state = authState, action) => {
  // console.log('auth state: ', authState)
  //DO SOME ACTION HERE
  switch(action.type) {
      case AUTHENTICATE_SUCCESS: 
          //CHECK FOR AN EXISTING SESSION/USER
          return {
              ...state,
              loading: false,
              error: null,
              USR: action.payload.USR,
              authenticated: true
          }
      case AUTHENTICATE_FAILURE: 
          return {
              ...state,
              loading: false,
              error: null,
              USR: {},
              authenticated: false
          }
      case LOGIN_BEGIN:
          //MARK STATE AS LOADING TO TRIGGER ANY ANIMATIONS NECESSARY
          return {
              ...state,
              loading: true,
              error: null
          }
      case LOGIN_SUCCESS:
          //SUCCESSFULL LOGIN, SET USER OBJECT IN STATE
          sessionStorage.setItem('USR', JSON.stringify({USR: action.payload.USR}))
          return {
              ...state,
              loading: false,
              USR: action.payload.USR,
              authenticated: true
          }
      case LOGIN_FAILURE:
          //FAILED LOGIN, MAKE SURE USER IS EMPTY AND LOADING IS FALSE
          return {
              ...state,
              loading: false,
              error: action.payload,
              USR: {}
          }
      case FETCH_ENDPOINT_BEGIN:
          //BEGINNING PROCESS OF GETTING ENDPOINT ID, EITHER FROM SESSION OR A NEW ONE FROM SERVER
          return {
              ...state,
              error: null
          }
      case FETCH_ENDPOINT_SUCCESS:
          //SUCCESSFULLY RETRIEVED THE EP ID, SETTING TO STATE
          return {
              ...state,
              EP: action.payload.EP
          }
      case FETCH_ENDPOINT_FAILURE:
          //FAILED TO GET THE ENPDOINT ID, DECIDE HOW TO HANDLE ERROR.
          return {
              ...state,
              epError: action.payload.ERR,
              EP: {}
          }
      case EDIT_LOGIN_FORM:
          return {
              ...state,
              AUTH: {
                  eml: action.payload.eml,
                  pass: action.payload.pass
              }
          }
      case DEAUTH:
          return {
              ...state,
              USR: {},
              acct: {},
              authenticated: false
          }
      case RESET_APP: 
          return {
              authState
          }

      case PW_RESET_REQUEST_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        }
      case PW_RESET_REQUEST_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null
        }
        case PW_RESET_REQUEST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload
          }
        case PW_UPDATE_REQUEST_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          }
        case PW_UPDATE_REQUEST_SUCCESS:
          return {
            ...state,
            loading: false,
            error: null
          }
        case PW_UPDATE_REQUEST_FAILURE:
          return {
            ...state,
            loading: true,
            error: action.payload
          }
        case CLEAR_ERR:
          return {
            ...state,
            error: null,
            loading: false
          }
        case SET_AUTHZ_TIMER:
          return {
            ...state,
            auth_timeout: action.payload
          }
      default:
          // 
          return state
  }
}