import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import {
  updatePasswordRequest
} from '../redux/actions/authActions'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button
} from "shards-react";

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error
})

const mapDispatchToProps = dispatch => ({
  updatePassword: (pass,urlId) => dispatch(updatePasswordRequest(pass,urlId))
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {
  

  const [pass1,setPass1] = useState('')
  const [pass2,setPass2] = useState('')
  const [valid,setIsValid] = useState(true)
  const [showModal,setShow] = useState(false)
  const [message,setMessage] = useState(null)
  const [sent,setIsSent] = useState(false)

  useEffect(() => {

    if(props.error !== undefined && props.error !== null) {
      setMessage(props.error)
      setIsSent(false)
      props.clearError()
    }
    if(props.loading) {
      setIsSent(true)
    }
    if(sent && !props.loading && !props.error) {
      setShow(true)
      setIsSent(false)
    }
  })
  
  
  return (
 <Container fluid className="main-content-container h-100 px-4">
    <Row noGutters className="h-100">
      <Col lg="3" md="5" className="auth-form mx-auto my-auto">
        <Card>
          <CardBody>
            {/* Logo */}
            <img
              className="auth-form__logo d-table mx-auto mb-3"
              src={require("../images/ETC_RISK.png")}
              alt="Shards Dashboards - Register Template"
              style={{maxWidth: '7vw', maxHeight: '7vh'}}
            />

            {/* Title */}
            <h5 className="auth-form__title text-center mb-4">
              Password Reset Form
            </h5>

            {/* Form Fields */}
            <Form>
              {message && <b><p className="text-center mb-1"
										style={{color: 'red'}}>Unable to update your password. Please try again later.</p></b>}
              <FormGroup>
                <label htmlFor="pass1">New Password</label>
                <FormInput
                  type="password"
                  id="pass1"
                  placeholder="Enter password"
                  autoComplete="email"
                  value={pass1}
                  onKeyPress={(e) => {
                    if(e.charCode === 13)
                      e.preventDefault()
                  }}
                  onChange={(e) => {
                    let _val = e.target.value
                    setPass1(_val)
                    setMessage(null)
                  }}
                />
                <label className="mt-2" htmlFor="pass2">Repeat New Password</label>
                {!valid && <b><p className="text-center mb-1"
										style={{color: 'red'}}>Passwords must match.</p></b>}
                <FormInput
                  invalid={!valid}
                  type="password"
                  id="pass2"
                  placeholder="Re-enter password"
                  autoComplete="email"
                  value={pass2}
                  onKeyPress={(e) => {
                    if(e.charCode === 13)
                      e.preventDefault()
                  }}
                  onChange={(e) => {
                    let _val = e.target.value
                    setPass2(_val)
                    setIsValid(_val.length > 0 ? (_val === pass1) : true)
                    setMessage(null)
                  }}
                />
              </FormGroup>
              <Button
                pill
                theme="accent"
                className="d-table mx-auto"
                disabled={!valid || pass1.length === 0 || pass2.length === 0}
                onClick={(e) => {
                  props.updatePassword(pass1,props.history.location.pathname.split('passwordreset/')[1])
                }}
              >
                Reset Password
              </Button>
            </Form>
          </CardBody>
        </Card>

        {/* Meta Details */}
        <div className="auth-form__meta d-flex mt-4">
          <Link to="/login" className="mx-auto">
            Take me back to login.
          </Link>
        </div>
      </Col>
    </Row>
      <Modal
        show={showModal}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Password Update Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your password has been successfully updated.</Modal.Body>
        <Modal.Footer>
          <Button
            pill
            theme="accent"
            className="d-table mx-auto"
            onClick={() => {
              props.history.push("/login")
            }}>
            Back to Login
          </Button>
        </Modal.Footer>
      </Modal>
  </Container>
  )
})