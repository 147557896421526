import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Repos, Breadcrumbs, NewDirectory, FolderContentsTable } from '../components/folder-contents'
import { Container, Row } from 'shards-react'
import PageTitle from "../components/common/PageTitle"
import SectionTitle from "../components/file-manager-cards/SectionTitle"
import FileDropzone from "../components/file-manager-list/FileDropzone"
import url from '../connection/url'
import {
  fetchAccount
} from '../redux/actions/accountActions'

const mapStateToProps = state => ({
  acct: state.acct.ACCT,
  repo: state.repo.activeRepo,
  fldrs: state.repo.directories,
  usr: state.auth.USR
})

const mapDispatchToProps = dispatch => ({
  getAccount: () => dispatch(fetchAccount())
})

export default connect(mapStateToProps,mapDispatchToProps)(({ acct, usr, fldrs, repo, getAccount }) => {

  useEffect(() => {
    console.log('acct: ', acct)
    if(!acct.ID || acct.ID !== usr.ACCT.ID) {
      getAccount()
    }
  },[repo])

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="File Manager" subtitle="Dashboards" className="text-sm-left mb-3" />
      </Row>

      {/* Repositories List */}
      <div className="file-manager file-manager-cards" >
        <SectionTitle title="Repositories" />
        <Repos />
      </div>

      {/* Directories Breadcrumbs */}
      <div className="file-manager file-manager-cards" >
        <SectionTitle title="Directories" />
        {<Breadcrumbs />}
      </div>

      {/* Common Law Downloadable link */}
      <SectionTitle title="Resources" />
      <a 
        href={`${url}/ui/2/repos/2/fldrs/10188/docs/84775/dwnld`}
      >Common_Law_Marriage_Affidavit.docx</a>

      {/* New Directory Form */}
      <div className="file-manager file-manager-cards" >
        <NewDirectory />
      </div>

      {/* UPLOADS */}
      <FileDropzone />

      {/* Folder contents Table */}
      <FolderContentsTable />
      
    </Container>
  )
})