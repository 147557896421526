import url from '../../connection/url'
import axios from '../../connection/axiosHandler'
import uuid from 'uuid/v4'
import React from 'react'

import store from '../store'

//FETCH USER
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS"
export const AUTHENTICATE_FAILURE = "AUTHENTICATE_FAILURE"

export const INV_SESSION = "Invalid Session."

//LOGIN ACTION DECLARATIONS
export const LOGIN_BEGIN = "LOGIN_BEGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

//ENDPOINT ACTION DECLARATIONS
export const FETCH_ENDPOINT_BEGIN = "FETCH_ENDPOINT_BEGIN"
export const FETCH_ENDPOINT_SUCCESS = "FETCH_ENDPOINT_SUCCESS"
export const FETCH_ENDPOINT_FAILURE = "FETCH_ENDPOINT_FAILURE"

//PASSWORD RESET REQUEST
export const PW_RESET_REQUEST_BEGIN = "PW_RESET_REQUEST_BEGIN"
export const PW_RESET_REQUEST_SUCCESS = "PW_RESET_REQUEST_SUCCESS"
export const PW_RESET_REQUEST_FAILURE = "PW_RESET_REQUEST_FAILURE"

//PASSWORD UPDATE REQUEST
export const PW_UPDATE_REQUEST_BEGIN = "PW_UPDATE_REQUEST_BEGIN"
export const PW_UPDATE_REQUEST_SUCCESS = "PW_UPDATE_REQUEST_SUCCESS"
export const PW_UPDATE_REQUEST_FAILURE = "PW_UPDATE_REQUEST_FAILURE"

export const DEAUTH = "DEAUTH"

export const RESET_APP = "RESET_APP"

export const CLEAR_ERR = "CLEAR_ERR"

//LOGIN FORM EDIT ACTIONS
export const EDIT_LOGIN_FORM = "EDIT_LOGIN_FORM"

export const SET_AUTHZ_TIMER = "SET_AUTHZ_TIMER"


export const clearError = () => ({
     type: CLEAR_ERR
 })
 
/**
 * ACTION TO SET THE LOCALLY SAVED USER IN STATE IF THE SESSION IS VALID
 */
export const authenticateSuccess = USR => ({
    type: AUTHENTICATE_SUCCESS,
    payload: USR
})
/**
 * ACTION TO REMOVE THE LOCALLY SAVED USER SHOULD THE SESSION SHOULD BE INVALIDATED
 */
export const authenticateFailure = (ERR) => ({
    type: AUTHENTICATE_FAILURE,
    payload: ERR
})

/**
 * LOGIN ACTION FOR BEGINNING THE AUTHENTICATION PROCESS, USED FOR DISPLAYING LOADING
 */
export const loginBegin = () => ({
    type: LOGIN_BEGIN
})
/**
 * LOGIN ACTION FOR MARKING THE AUTHENTICATION PROCESS AS A SUCCESS, RETURNING A USER
 * @param {User Object} user 
 */
export const loginSuccess = USR => ({
    type: LOGIN_SUCCESS,
    payload: { USR }
})

export const deauthenticate = () => ({
    type: DEAUTH
})

/**
 * LOGIN ACTION FOR MARKING THE AUTHENTICATION PROCESS AS A FAILURE, RETURNING THE ERROR
 * @param {Error Message} error 
 */
export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: error 
})

/**
 * ENDPOINT ACTION FOR BEGINNING THE PROCESS OF GETTING AN ID FOR AN ENDPOINT
 */
export const fetchEndpointBegin = () => ({
    type: FETCH_ENDPOINT_BEGIN
})
/**
 * ENDPOINT ACTION FOR FETCHING THE ENDPOINT ID NECESSARY FOR AUTHENTICATION. WILL BE STORED IN LOCAL STORAGE IF FETCHED PREVIOUSLY.
 * @param {Endpoint Id} epid
 */
export const fetchEndpointSuccess = epid => ({
    type: FETCH_ENDPOINT_SUCCESS,
    payload: { epid }
})
/**
 * ENDPOINT ACTION FOR RETURNING THE ERROR MESSAGE FOR A FAILED ATTEMPT TO GATHER THE ENPOINT ID
 * @param {Error Message} error 
 */
export const fetchEndpointFailure = error => ({
    type: FETCH_ENDPOINT_FAILURE,
    payload: { error }
})
/**
 * ACTION FOR EDITING THE LOGIN EMAIL FIELD
 * @param {Email field} email 
 */
export const editLoginForm = (email,password) => ({
    type: EDIT_LOGIN_FORM,
    payload: { eml: email,pass: password }
})

export const resetApp = () => ({
    type: RESET_APP
})

//PASSWORD RESET REQUEST
export const resetPasswordRequestBegin = () => ({
    type : PW_RESET_REQUEST_BEGIN
})
export const resetPasswordRequestSuccess = () => ({
    type: PW_RESET_REQUEST_SUCCESS
})
export const resetPasswordRequestFailure = (err) => ({
    type: PW_RESET_REQUEST_FAILURE,
    payload: err
})

//PASSWORD UPDATE REQUEST
export const updatePasswordRequestBegin = () => ({
    type: PW_UPDATE_REQUEST_BEGIN
})
export const updatePasswordRequestSuccess = () => ({
    type: PW_UPDATE_REQUEST_SUCCESS
})
export const updatePasswordRequestFailure = (err) => ({
    type: PW_UPDATE_REQUEST_FAILURE,
    payload: err
})
export const _setAuthzTimer = (func) => {
    clearTimeout(store.getState().auth.auth_timeout)
    return {
        type: SET_AUTHZ_TIMER,
        payload: func
    }
}

/**
 * initApp is used to load and initiate all of the methods necessary
 * on first load or on refresh to properly setup all storage and 
 * objects.
 */
export const initApp = () => {
    return async dispatch => {
        //clear old variable for app version
        if(sessionStorage.getItem('VSN') !== undefined) {
            sessionStorage.removeItem('VSN')
        }
        if(sessionStorage.getItem('SDX_VSN') !== undefined && sessionStorage.getItem('SDX_VSN') !== process.env.REACT_APP_VERSION) {
            sessionStorage.clear()
            sessionStorage.setItem('SDX_VSN', process.env.REACT_APP_VERSION)
            window.location.reload()
        }else if(sessionStorage.length > 0 && sessionStorage.getItem('SDX_VSN') === null) {
            sessionStorage.clear()
            sessionStorage.setItem('SDX_VSN', process.env.REACT_APP_VERSION)
            window.location.reload()
        }
    }
}

export const setAuthzTimer = () => {

    return async dispatch => {
        const check = await ack()
        if(!check) {
            dispatch(logout())
        }else {
            let timer = setTimeout(async () => {
                const check = await ack()
                if(!check) {
                    dispatch(logout())
                }
            },(31000 * 60))
            dispatch(_setAuthzTimer(timer))
        }
    }
}

export const ack = async () => {
    const response = await axios({
        baseURL: `${url}/ack`,
        crossDomain: true,
        timeout: 200000,
        withCredentials: true,
        credentials: 'same-origin',
        responseType: 'json',
        method: 'get'
    })
    return (response.data.RSP && response.data.RSP.ACK)      
}

/**
 * fetchLocalUser looks for an existing user in sessionStorage. If one is found,
 * a call to the server to verify the validity of the session is made. If this returns
 * true, the user is set in state. 
 * 
 * This is done to prevent a refresh from wiping the current logged in session from the browser.
 */
export function authenticate() {
    return async dispatch => {
        let usr
        if((usr=JSON.parse(sessionStorage.getItem('USR'))) !== null) {
            const response = await axios({
                baseURL: `${url}/ack`,
                crossDomain: true,
                timeout: 200000,
                withCredentials: true,
                credentials: 'same-origin',
                responseType: 'json',
                method: 'get'
            })
            if(response.data.ERR !== null) {
                dispatch(authenticateFailure(response.data.ERR))
            }else if(response.data.RSP !== null) {
                if(response.data.RSP.ACK === 'true') {

                    dispatch(authenticateSuccess(usr))
                }else {
                    sessionStorage.removeItem('USR')
                    dispatch(authenticateFailure())
                }
            }
        }else {
            dispatch(authenticateFailure())
        }
    }
}

//WILL RETURN TRUE IF THE SESSION IS VALID
export const validateSessionError = (error) => {
    console.log('validate session error: ', error === INV_SESSION)
    return (error === INV_SESSION)
}

/**
 * fetchEndpoint is used to either pull the valid enpointId from session storage or create
 * a new endpoint and retrieve the Id from the server. Necessary for authentication.
 */
export function fetchEndpoint() {
    return async dispatch => {
        dispatch(fetchEndpointBegin())
        if(sessionStorage.getItem('ep.id') !== null) {
            dispatch(fetchEndpointSuccess({EP: {ID: sessionStorage.getItem('ep.id')}}))
        }else {
            let endpoint = uuid()
            try {
                const response = await axios({
                    baseURL: `${url}/eps/add`,
                    crossDomain: true,
                    timeout: 200000,
                    withCredentials: true,
                    credentials: 'same-origin',
                    responseType: 'json',
                    method: 'post',
                    data: {
                        UUID: endpoint,
                        HOST: window.location.hostname
                    }
                })
                if(response.data.ERR !== null) {
                    dispatch(fetchEndpointFailure(response.data.ERR))
                }else if(response.data.RSP !== null) {
                    sessionStorage.setItem('ep.id', response.data.RSP.EP.ID)
                    dispatch(fetchEndpointSuccess(response.data.RSP))
                    return response.data.RSP.ID
                }
            }catch(error) {
                return dispatch(fetchEndpointFailure(error))
            }
        }
    }

}
/**
 * login takes the email and password as parameters, and attempts to use the 
 * previously created endpoint id to login and authenticate with the sdx server
 * 
 * @param {email string} email 
 * @param {password string} password 
 */
export function login(email, password) {

    return async dispatch => {
        
        let epId = sessionStorage.getItem('ep.id')
        if(epId !== null) {
            dispatch(loginBegin())
            try {
                const response = await axios({
                    baseURL: `${url}/authw`,
                    crossDomain: true,
                    timeout: 200000,
                    withCredentials: true,
                    credentials: 'same-origin',
                    responseType: 'json',
                    method: 'post',
                    data: {
                        EML: email,
                        PW: password,
                        ID: epId
                    }
                });
                if (response.data.ERR !== null) {
                    if(response.data.ERR === 'SecurityModule.authenticate: Invalid Credentials.') {
                        let error = {
                            MSG: response.data.ERR,
                            TP: 'INV_LOGIN'
                        }
                        dispatch(loginFailure(error))
                    }else
                        dispatch(loginFailure(response.data.ERR))
                }
                else if (response.data.RSP !== null) {
                    // sessionStorage.setItem('USR', {USR: JSON.stringify(response.data.RSP.USR)})
                    // dispatch(resetApp())
                    dispatch(loginSuccess(response.data.RSP.USR))
                }
            }
            catch (error) {
                return dispatch(loginFailure(error));
            }
        }else {
            return dispatch(loginFailure('Unable to locate endpoint Id.'))
        }
    }
}

/**
 * 
 * resetPasswordRequest sends an email address to the server 
 * to be validated as registered, and if so, sends an email to
 * the specified address with instructions on how to reset their 
 * password.
 * 
 * @param {*} email 
 */
export const resetPasswordRequest = (email) => {
    return async dispatch => {
        dispatch(resetPasswordRequestBegin())
        const response = await axios({
            baseURL: `${url}/pwd`,
            crossDomain: true,
            timeout: 200000,
            withCredentials: true,
            credentials: 'same-origin',
            responseType: 'json',
            method: 'post',
            data: { 
                EML: email, 
                HOST: window.location.hostname
            }
        })
        if(response.data.ERR !== null) {
            dispatch(resetPasswordRequestFailure(response.data.ERR))
        }else if(response.data.RSP !== null && response.data.RSP.ACK === 'true' || response.data.RSP.ACK === true) {
            dispatch(resetPasswordRequestSuccess())
        }
    }
}

/**
 * updatePasswordRequest sends the unique url identifier for the 
 * specified password request, as well as the new password to be 
 * set on the User's account tied with the PasswordReset request 
 * using the unique url identifer.
 * 
 * @param {*} password 
 * @param {*} urlId 
 */
export const updatePasswordRequest = (password,urlId) => {
    return async dispatch => {
        dispatch(updatePasswordRequestBegin())
        const response = await axios({
            baseURL: `${url}/pwd/upd`,
            crossDomain: true,
            timeout: 200000,
            withCredentials: true,
            credentials: 'same-origin',
            responseType: 'json',
            method: 'post',
            data: { ID: urlId, PW: password }
        })
        if(response.data.ERR !== null) {
            dispatch(updatePasswordRequestFailure(response.data.ERR))
        }else if(response.data.RSP !== null && response.data.RSP.ACK === 'true' || response.data.RSP.ACK === true) {
            dispatch(updatePasswordRequestSuccess())
        }
    }
}

export const logout = () => {
    console.log('logout called')
    return async dispatch => {
        const response = await axios({
            baseURL: `${url}/authd`,
            crossDomain: true,
            timeout: 200000,
            withCredentials: true,
            credentials: 'same-origin',
            responseType: 'json',
            method: 'get'
        })
        if(response.data.RSP.ACK !== null) {
            dispatch(deauthenticate())
            sessionStorage.removeItem('USR')
            sessionStorage.removeItem('SDX_VSN')
            window.location.reload()
        }
    }
}