import React from "react";

import {Redirect} from 'react-router-dom'

// Layout Types
import { DefaultLayout, IconSidebar, EmptyLayout } from "./layouts";

// Route Views
import UserProfileLite from "./views/UserProfileLite";
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import FileManagerList from "./views/FileManagerList";
import ComponentsOverview from "./views/ComponentsOverview";
import AccountHome from "./views/AccountHome"
import AddDocumentType from './views/AddDocumentType'
import EditDocumentType from './views/EditDocumentType';
import FolderContentsView from './views/FolderContentsView'
import PasswordResetForm from './views/PasswordResetForm'

const BlankIconSidebarLayout = ({ children }) => (
  <IconSidebar noNavbar noFooter>
    {children}
  </IconSidebar>
);

// const loggedIn = store.getState().user.ID !== undefined ? true : false

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/file-list" />
  },
  {
    path: "/passwordreset",
    layout: EmptyLayout,
    component: PasswordResetForm
  },
  {
    path: "/account",
    layout: DefaultLayout,
    component: AccountHome,
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  // {
  //   path: "/file-list",
  //   layout: DefaultLayout,
  //   component: FileManagerList
  // },
  {
    path: "/file-list",
    layout: DefaultLayout,
    component: FolderContentsView
  },
  {
    path: "/add-template",
    layout: DefaultLayout,
    component: AddDocumentType
  },
  {
    path: "/edit-template",
    layout: DefaultLayout,
    component: EditDocumentType
  },
  {
    path: "/login",
    layout: EmptyLayout,
    component: Login
  },
  {
    path: "/forgot-password",
    layout: EmptyLayout,
    component: ForgotPassword
  },
  // BEGIN COMMENT
  // {
  //   path: "/analytics",
  //   layout: DefaultLayout,
  //   component:  Analytics
  // },
  // {
  //   path: "/ecommerce",
  //   layout: DefaultLayout,
  //   component: OnlineStore
  // },
  // {
  //   path: "/blog-overview",
  //   layout: DefaultLayout,
  //   component: BlogOverview
  // },
  // {
  //   path: "/user-profile",
  //   layout: DefaultLayout,
  //   component: UserProfile
  // },
  // {
  //   path: "/user-profile-lite",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  // {
  //   path: "/edit-user-profile",
  //   layout: DefaultLayout,
  //   component: EditUserProfile
  // },
  
  // {
  //   path: "/register",
  //   layout: BlankIconSidebarLayout,
  //   component: Register
  // },
  
  // {
  //   path: "/change-password",
  //   layout: BlankIconSidebarLayout,
  //   component: ChangePassword
  // },
  // {
  //   path: "/file-manager-list",
  //   layout: DefaultLayout,
  //   component: FileManagerList
  // },
  // {
  //   path: "/file-manager-cards",
  //   layout: DefaultLayout,
  //   component: FileManagerCards
  // },
  // {
  //   path: "/transaction-history",
  //   layout: DefaultLayout,
  //   component: TransactionHistory
  // },
  // {
  //   path: "/calendar",
  //   layout: DefaultLayout,
  //   component: Calendar
  // },
  // {
  //   path: "/add-new-post",
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  // {
  //   path: "/errors",
  //   layout: BlankIconSidebarLayout,
  //   component: Errors
  // },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // },
  // {
  //   path: "/header-navigation",
  //   layout: HeaderNavigation,
  //   component: HeaderNav
  // },
  // {
  //   path: "/icon-sidebar-nav",
  //   layout: IconSidebar,
  //   component: IconSidebarView
  // },
  // {
  //   layout: EmptyLayout,
  //   component: () => <Redirect to="/" />
  // }
  //END COMMENT
];
