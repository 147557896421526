import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import uuid from 'uuid'
import { connect } from 'react-redux'

import { Button,Row,Col } from 'shards-react'

import { addJobs,addFileJobs,clearFileJobs } from '../../redux/actions/uploadActions'
import { toggleNotificationDropdown } from '../../redux/actions/layoutActions'
import ReactTooltip from 'react-tooltip'
import { ButtonGroup } from "react-bootstrap";

class FileDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      globSize: 65536
    }
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleUpload = this.handleUpload.bind(this)
  }

  
  /**
   * method to handle the file drop.
   */
  handleFileDrop(files) {
    if(files instanceof Array) {
      let filesArray = []
      for(let file of files) {
        if(file !== null ? file instanceof File : false) {
          console.log('selected File: ', file)
          let uploadJob = {
            FLNM: file.name,
            SZ: file.size.toString(),
            GBS: parseInt((file.size % this.state.globSize == 0 ? file.size/this.state.globSize : (file.size/this.state.globSize)+1)),
            IDX: 1,
            GBSZ: (this.state.globSize < file.size ? this.state.globSize : file.size).toString(),
            ENCD: false.toString(),
            FILE: file,
            STEP: 'add-document',
            BIDX: 0,
            EIDX: this.state.globSize,
            UUID: new uuid(),
            DOC: {
              TTL: file.name,
              ACCT: {
                ID: this.props.acct.ID
              },
              REPO: {
                ID: this.props.repo.ID
              },
              FLDR: {
                ID: this.props.fldr.ID
              },
              USR: {
                ID: JSON.parse(sessionStorage.getItem('USR')).USR.ID
              },
              DOCTP: {
                ID: "19"
              }
            }
          }
          filesArray.push(uploadJob)
          console.log(uploadJob)
        }else {
          console.log('file not instanceof File')
        }
      }
      if(filesArray.length > 0) {
        console.log('adding files: ', filesArray)
        this.props.addFileJobs(filesArray)
      }else {
        console.log('filesArray length == 0')
      }
    }else {
      console.log('files not instanceof array')
    }
  }

  handleUpload(e) {
    console.log('handle upload called. jobs: ', this.props.jobs)
    if(this.props.jobs !== null ? this.props.jobs.length > 0 : false) {
      this.props.addUploadJobs(this.props.jobs)
      if(!this.props.notificationDropdown) {
        this.props.toggleNotifications()
      }
    }
  }

  render() {
    return (
      <div 
        // style={{height: "7em"}}
        className="file-manager__dropzone">
        <Dropzone 
        disabled={(this.props.fldr !== null && this.props.fldr !== undefined) && this.props.fldr.ID === undefined || this.props.jobs.length > 0}
        multiple={false}
        onDrop={this.handleFileDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone" , {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                <span>
                  <h6>
                    {isDragActive
                      ? "Drop files here!"
                      : "Click here to upload"}
                  </h6>
                </span>
              </div>
            );
          }}
        </Dropzone>
        <Row>
          <Col className="sm"></Col>
          <Col className="mt-4">
            <Col className="sm"></Col>
            <Col>
            </Col>
              <Col className="d-flex justify-content-center">

              {/* <ul
                style={{textAlign:'left'}}> */}
                {/* {this.state.files.map((file,index) => {
                  return (
                    <li
                      key={index}>
                      <a 
                        style={{color: 'red', cursor:'pointer'}}
                        onClick={(e) => {
                          if(this.state.files !== null ? this.state.files.length > 0 : false) {
                            let removedFile = this.state.files.find((element) => {
                              return element.name === file.name
                            })
                            if(removedFile !== null) {
                              let files = this.state.files
                              files.splice(this.state.files.indexOf(removedFile),1)
                              this.setState({files})
                            }
                          }
                        }}
                        ><span 
                          className="material-icons sdx-align-icon"
                          style={{paddingRight: '5px'}}
                        >cancel</span></a>{file.FLNM}</li>
                  )
                })} */}
                {(this.props.jobs !== undefined && this.props.jobs !== null) &&
                  this.props.jobs.map((file, index) => {
                    return (
                      <span
                      className="mb-2"
                      style={{color: "red"}}
                      key={index}>
                      {/* <a 
                        style={{color: 'red', cursor:'pointer'}}
                        data-for="btn-cancel"
                        data-tip="remove file"
                        onClick={(e) => {
                          // REMOVE FILE
                          this.props.clearJobs()
                        }}
                        ><span 
                          className="material-icons sdx-align-icon"
                          style={{paddingRight: '5px'}}
                        >cancel</span>
                      </a>
                      <a
                        style={{color: '#007bff', cursor:'pointer'}}
                        data-for="btn-upload"
                        data-tip="upload file"
                        onClick={(e) => {
                          // UPLOAD FILE
                          this.handleUpload(e)FileDropzonen"
                          style={{paddingRight: '5px'}}
                        >upload</span></a> */}
                        <ReactTooltip 
                          id="btn-upload" />
                        <ReactTooltip 
                          id="btn-cancel" />
                        {file.FLNM}</span>
                        
                    )
                  })
                }
              {/* </ul> */}
              </Col>
              <Col className="d-flex justify-content-center">
              <ButtonGroup size="sm">
                <Button
                  theme="danger"
                  hidden={this.props.jobs.length < 1}
                  onClick={(e) => {
                    //REMOVE FILE
                    this.props.clearJobs()
                  }}
                >Remove</Button>
                <Button
                  hidden={this.props.jobs.length < 1}
                  onClick={(e) => {
                    // UPLOAD FILE
                    this.handleUpload(e)
                  }}
                >Upload</Button>
              </ButtonGroup>
              </Col>
              {/* <Button 
                hidden={this.props.jobs.length < 1}
                data-for="btn-upload"
                data-tip="upload selected file(s)"
                onClick={() => {}}>
                <span className="material-icons">cloud_upload</span>
              </Button> */}
              
              
          </Col>
          <Col className="sm"></Col>
        </Row>
        <div className="container">
          <div className="row">
            <div className="col-sm"></div>
            <div className="col-sm md-4">
              
            </div>
            <div className="col-sm"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.upload.fileJobs,
  upload: state.upload,
  repo: state.repo.activeRepo,
  acct: state.acct.ACCT,
  fldr: state.repo.activeDir,
  notificationDropdown: state.layout.showNotifications,
  toggleTips: state.layout.toggleHideTooltips
})

const mapDispatchToProps = dispatch => ({
  addFileJobs: (fls) => dispatch(addFileJobs(fls)),
  addUploadJobs: (jobs) => dispatch(addJobs(jobs)),
  clearJobs: () => dispatch(clearFileJobs()),
  toggleNotifications: () => dispatch(toggleNotificationDropdown())
})

export default connect(mapStateToProps,mapDispatchToProps)(FileDropzone)
