import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import EmailValidator from 'email-validator'
import Modal from 'react-bootstrap/Modal'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button
} from "shards-react";
import { connect } from "react-redux";
import {
  resetPasswordRequest,
  clearError
} from '../redux/actions/authActions'

const mapStateToProps = state => ({
  error: state.auth.error,
  loading: state.auth.loading
})

const mapDispatchToProps = dispatch => ({
  resetPass: (email) => dispatch(resetPasswordRequest(email)),
  clearError: () => dispatch(clearError())
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {
  
  const [valid,setIsValid] = useState(true)
  const [email,setEmail] = useState("")
  const [showModal,setShow] = useState(false)
  const [message,setMessage] = useState(null)
  const [sent,setIsSent] = useState(false)

  useEffect(() => {

    if(props.error !== undefined && props.error !== null) {
      setMessage(props.error)
      setIsSent(false)
      props.clearError()
    }
    if(props.loading) {
      setIsSent(true)
    }
    if(sent && !props.loading && !props.error) {
      setShow(true)
      setIsSent(false)
    }
  })
  
  return (
  <Container fluid className="main-content-container h-100 px-4">
    <Row noGutters className="h-100">
      <Col lg="3" md="5" className="auth-form mx-auto my-auto">
        <Card>
          <CardBody>
            {/* Logo */}
            <img
              className="auth-form__logo d-table mx-auto mb-3"
              src={require("../images/ETC_RISK.png")}
              alt="Shards Dashboards - Register Template"
              style={{maxWidth: '7vw', maxHeight: '7vh'}}
            />

            {/* Title */}
            <h5 className="auth-form__title text-center mb-4">
              Reset Password
            </h5>

            {/* Form Fields */}
            <Form>
              <FormGroup>
                <label htmlFor="exampleInputEmail1">Email address</label>
                {message && <b><p className="text-center mb-1"
										style={{color: 'red'}}>No Email found.</p></b>}
                <FormInput
                  type="email"
                  id="exampleInputEmail1"
                  placeholder="Enter email"
                  autoComplete="email"
                  value={email}
                  invalid={!valid}
                  onKeyPress={(e) => {
                    if(e.charCode === 13)
                      e.preventDefault()
                  }}
                  onChange={(e) => {
                    let _val = e.target.value
                    setIsValid(EmailValidator.validate(_val))
                    setEmail(_val)
                    setMessage(null)
                  }}
                />
                <small className="form-text text-muted text-center">
                  You will receive an email with a unique token.
                </small>
              </FormGroup>
              <Button
                pill
                theme="accent"
                className="d-table mx-auto"
                disabled={!valid || email.length === 0}
                onClick={(e) => {
                  if(valid) {
                    props.resetPass(email)
                  }
                }}
              >
                Reset Password
              </Button>
            </Form>
          </CardBody>
        </Card>

        {/* Meta Details */}
        <div className="auth-form__meta d-flex mt-4">
          <Link to="/login" className="mx-auto">
            Take me back to login.
          </Link>
        </div>
      </Col>
    </Row>
    <Modal
      show={showModal}
      >
      <Modal.Header closeButton ={false}>
          <Modal.Title>Reset Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>An email has been sent with further instructions <br/> on how to change your password.</Modal.Body>
        <Modal.Footer>
          <Button 
          pill
          theme="accent"
          className="d-table mx-auto" 
          onClick={() => {
            props.history.push("/login")
          }}>
            Back to Login
          </Button>
        </Modal.Footer>
    </Modal> 
  </Container>
)})



